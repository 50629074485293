/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {CurrentConditionDto} from "$services/AaveCalculator.service";

export type CreateRoleDto = object;

export interface BaseEntityDto {
  name: string;
  description: string;
  alias: string;
  isViewed: boolean;
}

export interface CategoryResponseDto {
  name: string;
  description: string;
  alias: string;
  isViewed: boolean;
  isPro: boolean;
  studyCount: string;
  icon: string;
  articlesMetaData: BaseEntityDto[];
}

export interface ResponseArticleDto {
  name: string;
  description: string;
  alias: string;
  isViewed: boolean;
  data: string;
  access: boolean;
  errorArticleAlias: string;
  error_button_text: string;
  error_description: string;
  route_to: string;
}

export interface BooleanResponseDto {
  result: boolean;
}

export interface Role {
  /**
   * Уникальный идентификатор
   * @example "1"
   */
  id: number;
  /**
   * Уникальное Значение роли
   * @example "ADMIN"
   */
  value: string;
  /**
   * Описание роли
   * @example "Администратор"
   */
  name: string;
  /**
   * Токен для обращения в CMS
   * @example "hashtoken"
   */
  strapi_token: string;
}

export interface User {
  /**
   * Уникальный идентификатор
   * @example 1
   */
  id: number;
  /**
   * Уникальный идентификатор
   * @example "test@mail.ru"
   */
  email: string;
  /**
   * Подтвержденная почта
   * @example "true"
   */
  isActivated: boolean;
  /**
   * id в cloudpayments
   * @example "123-123fkdsf-dmsflds"
   */
  account_id: string;
  /**
   * id кошелька
   * @example "123-123fkdsf-dmsflds"
   */
  metamask_id: string;
  /**
   * Идентификатор дискорда
   * @example 1
   */
  discord_id: string;
  /** Телеграм */
  telegram: string;
  /** Роли */
  roles: Role;
  /** осталось дней подписки */
  days_left: number;
  /** статус подписки */
  subscribe_status: string;
  /** Можно в AcademyPro */
  can_to_subscribe: boolean;
  /** Название тарифа */
  rates_name: string;
}

export interface ChangePasswordDto {
  /**
   * Пароль
   * @example 123132
   */
  password?: string;
}

export interface ChangeEmailDto {
  oldEmail: string;
  newEmail: string;
}

export interface OffersResponseDto {
  currency: string;
  isUnlimited: boolean;
  alias: string;
  haveCredit: boolean;
  isBestOffer: boolean;
  beforeSalePrice: number;
  price: number;
  subscription_days: number;
  descriptionInDialog: string;
  descriptionInCard: string;
  ym_alias: string;
  data: string[];
}

export interface CategoryRulesResponseDto {
  alias: string;
}

export interface ArticleToolsResponseDto {
  ym_alias: string;
  name: string;
  icon: string;
  url: string;
}

export interface ToolItemsDto {
  url: string;
  name: string;
  icon: string;
}

export interface SubCategoryDto {
  id: string;
  name: string;
  tool_items: ToolItemsDto[];
}

export interface CategoriesToolsDto {
  id: string;
  name: string;
  subcategories: SubCategoryDto[];
}

export interface FAQResponseDto {
  name: string;
  description: string;
}

export interface ReviewResponseDto {
  description: string;
  name: string;
  login: string;
  social: string;
  avatar: string;
  url: string;
}

export interface Book {
  name: string;
  url: string;
}

export interface LibraryResponseDto {
  section: string;
  books: Book[];
}

export interface NftBlockResponseDto {
  caption: string;
  description: string;
  nft_club_name: string;
  get_nft_button: string;
  nft_list: FAQResponseDto[];
}

export interface CreateUserDto {
  /**
   * Почтовый адрес
   * @example "test@gmail.com"
   */
  email: string;
  /**
   * Пароль
   * @example 123132
   */
  password?: string;
  /**
   * Id пользователя в платформе
   * @example 123132
   */
  referrer_id?: number;
}

export interface TokenDto {
  /**
   * Почтовый адрес
   * @example "jwt"
   */
  token: string;
}

export interface SendEmailDto {
  /**
   * Почтовый адрес
   * @example "test@gmail.com"
   */
  email: string;
}

export interface MintNftDto {
  /** Хэш транзакци */
  tx_hash: string;
}

export interface MintedNFT {
  /**
   * Уникальный идентификатор
   * @example 1
   */
  id: number;
  /**
   * Уникальный идентификатор пользователя
   * @example 1
   */
  user_id: number;
  /**
   * Хеш транзакции
   * @example "0x9e4e8415e64a9051b835af2a898c843ced62ca1f99f1ceba37773e1136c878b1"
   */
  tx_hash: string;
  /**
   * Дата минта
   * @format date-time
   */
  createdAt: string;
}

export type AddToWhiteListDto = object;

export interface ResponseReferralDto {
  referral_link_platform: string;
  referral_link_landing: string;
  maxSum: number;
  availableSum: number;
}

export interface ResponseReferralsListDto {
  email: string;
  isPro: boolean;
}

export interface ResponsePaymentsOutHistoryDto {
  amount: number;
  status: string;
  createdAt: number;
}

export interface UpgradePaymentDto {
  invoice_id: string;
  new_offer_alias: string;
  referrer_id?: number;
  utm_content?: string;
}

export interface CreatePaymentDto {
  invoice_id: string;
  interval: string;
  offer_alias: string;
  referrer_id?: number;
  utm_content?: string;
}

export interface CreatePaymentsCryptoDto {
  tx_hash: string;
  amount: number;
  currency: string;
  network: string;
  interval?: string;
  offer_alias: string;
  referrer_id?: number;
}

export interface PaymentStatusRequestDto {
  tx_hash: string;
  network: string;
}

export interface CreateSubscriptionsCryptoDto {
  plan_id: string;
  metamask_id: string;
  period: number;
}

export interface CreateSubscribeAdminDto {
  /**
   * id транзакции
   * @example "13123123"
   */
  transactionId: number;
  /**
   * количество дней подписки
   * @example "90"
   */
  period: string;
}

export interface VerifyPromoCodeDto {
  code: string;
}

export interface PromoCode {
  /**
   * Название промокода
   * @example "YASDA"
   */
  name: string;
  /**
   * Скидка в процентах
   * @example 1
   */
  sale: number;
}

export interface GetNewsDto {
  next: string;
}

export interface News {
  provider: string;
  link: string;
  title: string;
  text_small: string;
  image: string;
  published_at: string;
}

export interface NewsResponseDto {
  next: string;
  news: News[];
}

export interface CreateCrmContactDto {
  /** @example 89652557006 */
  phone: number;
  /** @example "templar820@yandex.ru" */
  email: string;
  /** @example "2" */
  referrer_id: string;
  /** @example "infinity_offer" */
  rates_alias: string;
  /** @example "Иванов Иван Иванович" */
  name: string;
  /** @example "123123" */
  debt_id: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title WEB3ACADEMY.PRO
 * @version 1.0.0
 * @contact
 *
 * REST API DOCS WEB3ACADEMY
 */
export class Api<SecurityDataType extends unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  api = {
    calculatorControllerAvailableNetworks: (params: RequestParams = {}) =>
      this.http.request<{ networks: string[] }, any>({
        path: '/api/calculator/getAvailableNetworks',
        method: "GET",
        format: "json",
        ...params,
      }),

    calculatorControllerGetCurrentConditions: (data: {network: string}, params: RequestParams = {}) =>
        this.http.request<CurrentConditionDto, any>({
          path: `/api/calculator/current-conditions/${data.network}`,
          method: "GET",
          format: "json",
          ...params,
          }),


    /**
     * No description
     *
     * @name PrometheusControllerIndex
     * @request GET:/api/metrics
     * @response `200` `void`
     */
    prometheusControllerIndex: (params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/metrics`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @name RolesControllerCreate
     * @request POST:/api/roles
     * @response `201` `void`
     */
    rolesControllerCreate: (data: CreateRoleDto, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/roles`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name RolesControllerGetByValue
     * @request GET:/api/roles/{value}
     * @response `200` `void`
     */
    rolesControllerGetByValue: (value: string, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/roles/${value}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags category
     * @name CategoryControllerFindAll
     * @summary Получить Категории
     * @request GET:/api/category
     * @response `200` `(CategoryResponseDto)[]`
     */
    categoryControllerFindAll: (params: RequestParams = {}) =>
      this.http.request<CategoryResponseDto[], any>({
        path: `/api/category`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags category
     * @name CategoryControllerFindOne
     * @summary Получить Категорию
     * @request GET:/api/category/{alias}
     * @response `200` `CategoryResponseDto`
     */
    categoryControllerFindOne: (alias: string, params: RequestParams = {}) =>
      this.http.request<CategoryResponseDto, any>({
        path: `/api/category/${alias}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags articles
     * @name ArticlesControllerFindOne
     * @summary Получить статью
     * @request GET:/api/articles/{alias}
     * @response `200` `ResponseArticleDto`
     */
    articlesControllerFindOne: (alias: string, params: RequestParams = {}) =>
      this.http.request<ResponseArticleDto, any>({
        path: `/api/articles/${alias}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags articles
     * @name ArticlesControllerSetOrChangeStatus
     * @summary Отметить статью как просмотренную
     * @request POST:/api/articles/views/{alias}
     * @secure
     * @response `200` `BooleanResponseDto`
     * @response `401` `void` Не авторизован
     */
    articlesControllerSetOrChangeStatus: (alias: string, params: RequestParams = {}) =>
      this.http.request<BooleanResponseDto, void>({
        path: `/api/articles/views/${alias}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UsersControllerCheckSubscriptionByTelegram
     * @request GET:/api/user/check-subscription-by-telegram/{telegram}
     * @response `200` `void`
     */
    usersControllerCheckSubscriptionByTelegram: (telegram: string, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/user/check-subscription-by-telegram/${telegram}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UsersControllerUpdateUser
     * @summary Обновить пользователя
     * @request PATCH:/api/user
     * @secure
     * @response `200` `BooleanResponseDto`
     * @response `401` `void` Не авторизован
     */
    usersControllerUpdateUser: (data: User, params: RequestParams = {}) =>
      this.http.request<BooleanResponseDto, void>({
        path: `/api/user`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UsersControllerChangePassword
     * @summary Сменить пароль
     * @request POST:/api/user/change_password
     * @secure
     * @response `200` `BooleanResponseDto`
     * @response `401` `void` Не авторизован
     */
    usersControllerChangePassword: (data: ChangePasswordDto, params: RequestParams = {}) =>
      this.http.request<BooleanResponseDto, void>({
        path: `/api/user/change_password`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UsersControllerChangeEmail
     * @summary Сменить почту
     * @request POST:/api/user/change_email
     * @secure
     * @response `200` `any`
     * @response `401` `void` Не авторизован
     */
    usersControllerChangeEmail: (data: ChangeEmailDto, params: RequestParams = {}) =>
      this.http.request<any, void>({
        path: `/api/user/change_email`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rates
     * @name RatesControllerGetUpgradeOffers
     * @summary Получить предложения по улучшению тарифа
     * @request GET:/api/rates/upgradeOffers
     * @secure
     * @response `200` `(OffersResponseDto)[]`
     * @response `401` `void` Не авторизован
     */
    ratesControllerGetUpgradeOffers: (params: RequestParams = {}) =>
      this.http.request<OffersResponseDto[], void>({
        path: `/api/rates/upgradeOffers`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags pages_components
     * @name PagesComponentsControllerStartStudyRules
     * @summary Получить правило расстановки категорий на лендосе
     * @request GET:/api/pages_components/StartStudy
     * @response `200` `(CategoryRulesResponseDto)[]`
     */
    pagesComponentsControllerStartStudyRules: (params: RequestParams = {}) =>
      this.http.request<CategoryRulesResponseDto[], any>({
        path: `/api/pages_components/StartStudy`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags pages_components
     * @name PagesComponentsControllerTools
     * @summary Расстановка уроков в инструментах
     * @request GET:/api/pages_components/ToolsArticle
     * @response `200` `(ArticleToolsResponseDto)[]`
     */
    pagesComponentsControllerTools: (params: RequestParams = {}) =>
      this.http.request<ArticleToolsResponseDto[], any>({
        path: `/api/pages_components/ToolsArticle`,
        method: "GET",
        format: "json",
        ...params,
      }),

    getAmountOfMentorPayments: (params: RequestParams = {}) =>
        this.http.request<{ amount: number }, any>({
          path: `/api/limited_rate/amountOfPayments`,
          method: "GET",
          format: "json",
          ...params,
        }),
    /**
     * No description
     *
     * @tags pages_components
     * @name PagesComponentsControllerToolsCategories
     * @summary Расстановка категорий в инструментах
     * @request GET:/api/pages_components/ToolsCategories
     * @response `200` `(CategoriesToolsDto)[]`
     */
    pagesComponentsControllerToolsCategories: (params: RequestParams = {}) =>
      this.http.request<CategoriesToolsDto[], any>({
        path: `/api/pages_components/ToolsCategories`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags pages_components
     * @name PagesComponentsControllerGetFaq
     * @summary Расстановка уроков в инструментах
     * @request GET:/api/pages_components/FAQ
     * @response `200` `(FAQResponseDto)[]`
     */
    pagesComponentsControllerGetFaq: (params: RequestParams = {}) =>
      this.http.request<FAQResponseDto[], any>({
        path: `/api/pages_components/FAQ`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags pages_components
     * @name PagesComponentsControllerGetW3AValues
     * @summary Ценности W3A
     * @request GET:/api/pages_components/W3A_Values
     * @response `200` `(FAQResponseDto)[]`
     */
    pagesComponentsControllerGetW3AValues: (params: RequestParams = {}) =>
      this.http.request<FAQResponseDto[], any>({
        path: `/api/pages_components/W3A_Values`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags pages_components
     * @name PagesComponentsControllerGetReviews
     * @summary Отзывы о платформе
     * @request GET:/api/pages_components/Reviews
     * @response `200` `(ReviewResponseDto)[]`
     */
    pagesComponentsControllerGetReviews: (params: RequestParams = {}) =>
      this.http.request<ReviewResponseDto[], any>({
        path: `/api/pages_components/Reviews`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags pages_components
     * @name PagesComponentsControllerGetLibrary
     * @summary Библиотека
     * @request GET:/api/pages_components/ELibrary
     * @response `200` `(LibraryResponseDto)[]`
     */
    pagesComponentsControllerGetLibrary: (params: RequestParams = {}) =>
      this.http.request<LibraryResponseDto[], any>({
        path: `/api/pages_components/ELibrary`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags pages_components
     * @name PagesComponentsControllerGetRates
     * @summary Тарифы
     * @request GET:/api/pages_components/Rates
     * @response `200` `(OffersResponseDto)[]`
     */
    pagesComponentsControllerGetRates: (params: RequestParams = {}) =>
      this.http.request<OffersResponseDto[], any>({
        path: `/api/pages_components/Rates`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags pages_components
     * @name PagesComponentsControllerGetOffers
     * @summary Офера
     * @request GET:/api/pages_components/Offers
     * @response `200` `(OffersResponseDto)[]`
     */
    pagesComponentsControllerGetOffers: (params: RequestParams = {}) =>
      this.http.request<OffersResponseDto[], any>({
        path: `/api/pages_components/Offers`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags pages_components
     * @name PagesComponentsControllerGetMiniCourseOffers
     * @summary Офера
     * @request GET:/api/pages_components/MiniCourseOffers
     * @response `200` `(OffersResponseDto)[]`
     */
    pagesComponentsControllerGetMiniCourseOffers: (params: RequestParams = {}) =>
      this.http.request<OffersResponseDto[], any>({
        path: `/api/pages_components/MiniCourseOffers`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags pages_components
     * @name PagesComponentsControllerGetNftBlock
     * @summary Офера
     * @request GET:/api/pages_components/NftBlock
     * @response `200` `NftBlockResponseDto`
     */
    pagesComponentsControllerGetNftBlock: (params: RequestParams = {}) =>
      this.http.request<NftBlockResponseDto, any>({
        path: `/api/pages_components/NftBlock`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Авторизация
     * @name AuthControllerLogin
     * @summary Логин пользователя
     * @request POST:/api/auth/signin
     * @response `200` `TokenDto`
     */
    authControllerLogin: (data: CreateUserDto, params: RequestParams = {}) =>
      this.http.request<TokenDto, any>({
        path: `/api/auth/signin`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Авторизация
     * @name AuthControllerAuthentication
     * @summary Аутентификация
     * @request POST:/api/auth/authentication
     * @secure
     * @response `200` `BooleanResponseDto`
     * @response `401` `void` Не авторизован
     */
    authControllerAuthentication: (params: RequestParams = {}) =>
      this.http.request<BooleanResponseDto, void>({
        path: `/api/auth/authentication`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Авторизация
     * @name AuthControllerSendPassword
     * @summary Запрос на изменения пароля
     * @request POST:/api/auth/sendPassword
     * @response `200` `BooleanResponseDto`
     */
    authControllerSendPassword: (data: SendEmailDto, params: RequestParams = {}) =>
      this.http.request<BooleanResponseDto, any>({
        path: `/api/auth/sendPassword`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Авторизация
     * @name AuthControllerRegistration
     * @summary Регистрация пользователя
     * @request POST:/api/auth/signup
     * @response `200` `BooleanResponseDto`
     */
    authControllerRegistration: (data: CreateUserDto, params: RequestParams = {}) =>
      this.http.request<BooleanResponseDto, any>({
        path: `/api/auth/signup`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Авторизация
     * @name AuthControllerConfirm
     * @summary Подтверждение пользователя
     * @request POST:/api/auth/confirm
     * @response `200` `BooleanResponseDto`
     */
    authControllerConfirm: (data: TokenDto, params: RequestParams = {}) =>
      this.http.request<BooleanResponseDto, any>({
        path: `/api/auth/confirm`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Авторизация
     * @name AuthControllerResetConfirm
     * @summary Подтверждение пользователя
     * @request POST:/api/auth/reset_confirm
     * @response `200` `BooleanResponseDto`
     */
    authControllerResetConfirm: (data: TokenDto, params: RequestParams = {}) =>
      this.http.request<BooleanResponseDto, any>({
        path: `/api/auth/reset_confirm`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Авторизация
     * @name AuthControllerGetUserByToken
     * @summary Получи пользователя по токену
     * @request GET:/api/auth/me
     * @secure
     * @response `200` `User`
     * @response `401` `void` Не авторизован
     */
    authControllerGetUserByToken: (params: RequestParams = {}) =>
      this.http.request<User, void>({
        path: `/api/auth/me`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags nft
     * @name NftControllerMint
     * @summary Добавление информации о минте
     * @request POST:/api/nft/minted
     * @secure
     * @response `200` `MintedNFT`
     * @response `401` `void` Не авторизован
     */
    nftControllerMint: (data: MintNftDto, params: RequestParams = {}) =>
      this.http.request<MintedNFT, void>({
        path: `/api/nft/minted`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags nft
     * @name NftControllerAddToWhiteList
     * @summary Добавление информации о минте
     * @request POST:/api/nft/add-to-whitelist
     * @secure
     * @response `200` `BooleanResponseDto`
     * @response `401` `void` Не авторизован
     */
    nftControllerAddToWhiteList: (data: AddToWhiteListDto, params: RequestParams = {}) =>
      this.http.request<BooleanResponseDto, void>({
        path: `/api/nft/add-to-whitelist`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags nft
     * @name NftControllerCheckMintNft
     * @summary Проверка возможности минта
     * @request GET:/api/nft/check-mint
     * @secure
     * @response `200` `BooleanResponseDto`
     * @response `401` `void` Не авторизован
     */
    nftControllerCheckMintNft: (params: RequestParams = {}) =>
      this.http.request<BooleanResponseDto, void>({
        path: `/api/nft/check-mint`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags referral
     * @name ReferralControllerCreatePaymentsOut
     * @summary Сгенерировать токен реферала
     * @request POST:/api/referral/create_payments_out
     * @secure
     * @response `200` `BooleanResponseDto`
     * @response `401` `void` Не авторизован
     */
    referralControllerCreatePaymentsOut: (params: RequestParams = {}) =>
      this.http.request<BooleanResponseDto, void>({
        path: `/api/referral/create_payments_out`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags referral
     * @name ReferralControllerGetReferrerLink
     * @summary Вернуть данные по реферальной системе
     * @request GET:/api/referral
     * @secure
     * @response `200` `ResponseReferralDto`
     * @response `401` `void` Не авторизован
     */
    referralControllerGetReferrerLink: (params: RequestParams = {}) =>
      this.http.request<ResponseReferralDto, void>({
        path: `/api/referral`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags referral
     * @name ReferralControllerGetReferralsList
     * @summary Вернуть данные по реферальной системе
     * @request GET:/api/referral/list
     * @secure
     * @response `200` `(ResponseReferralsListDto)[]`
     * @response `401` `void` Не авторизован
     */
    referralControllerGetReferralsList: (params: RequestParams = {}) =>
      this.http.request<ResponseReferralsListDto[], void>({
        path: `/api/referral/list`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    referralControllerGetRegisteredReferralsList: (params: RequestParams = {}) =>
        this.http.request<string[], void>({
          path: `/api/referral/list/registered`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        }),

    /**
     * No description
     *
     * @tags referral
     * @name ReferralControllerGetPaymentsOutHistory
     * @summary История о выводах из платформы
     * @request GET:/api/referral/payments_out_list
     * @secure
     * @response `200` `(ResponsePaymentsOutHistoryDto)[]`
     * @response `401` `void` Не авторизован
     */
    referralControllerGetPaymentsOutHistory: (params: RequestParams = {}) =>
      this.http.request<ResponsePaymentsOutHistoryDto[], void>({
        path: `/api/referral/payments_out_list`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment
     * @name PaymentControllerCreateTransaction
     * @summary Создать транзакцию хук
     * @request POST:/api/payment/cloudpayments_transaction_hook
     * @response `200` `BooleanResponseDto`
     */
    paymentControllerCreateTransaction: (params: RequestParams = {}) =>
      this.http.request<BooleanResponseDto, any>({
        path: `/api/payment/cloudpayments_transaction_hook`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment
     * @name PaymentControllerUpgradeRate
     * @summary Апгрейд тарифа
     * @request POST:/api/payment/upgrade_rate
     * @secure
     * @response `200` `BooleanResponseDto`
     * @response `401` `void` Не авторизован
     */
    paymentControllerUpgradeRate: (data: UpgradePaymentDto, params: RequestParams = {}) =>
      this.http.request<BooleanResponseDto, void>({
        path: `/api/payment/upgrade_rate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payment
     * @name PaymentControllerCreateSubscribe
     * @summary Создать подписку
     * @request POST:/api/payment/cloudpayments_subscribe
     * @response `200` `BooleanResponseDto`
     */
    paymentControllerCreateSubscribe: (data: CreatePaymentDto, params: RequestParams = {}) =>
      this.http.request<BooleanResponseDto, any>({
        path: `/api/payment/cloudpayments_subscribe`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags subscriptions
     * @name SubscriptionsControllerCreateTransaction
     * @summary Хук на обновление статуса подписки
     * @request POST:/api/subscriptions/cloudpayments_subscriptions_hook
     * @response `200` `BooleanResponseDto`
     */
    subscriptionsControllerCreateTransaction: (params: RequestParams = {}) =>
      this.http.request<BooleanResponseDto, any>({
        path: `/api/subscriptions/cloudpayments_subscriptions_hook`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags subscriptions
     * @name SubscriptionsControllerDeleteSubscribe
     * @summary Отменить подписку
     * @request POST:/api/subscriptions/cloudpayments_delete_subscribe
     * @secure
     * @response `200` `BooleanResponseDto`
     * @response `401` `void` Не авторизован
     */
    subscriptionsControllerDeleteSubscribe: (params: RequestParams = {}) =>
      this.http.request<BooleanResponseDto, void>({
        path: `/api/subscriptions/cloudpayments_delete_subscribe`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags subscriptions
     * @name SubscriptionsControllerCreate
     * @summary Создать подписку через сваггер
     * @request POST:/api/subscriptions/create/{id}
     * @response `200` `BooleanResponseDto`
     */
    subscriptionsControllerCreate: (id: string, params: RequestParams = {}) =>
      this.http.request<BooleanResponseDto, any>({
        path: `/api/subscriptions/create/${id}`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name PaymentsCryptoControllerCreate
     * @request POST:/api/payments-crypto
     * @response `201` `void`
     */
    paymentsCryptoControllerCreate: (data: CreatePaymentsCryptoDto, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/payments-crypto`,
        method: "POST",
        body: data,
        format: 'json',
        type: ContentType.Json,
      }),

    /**
     * No description
     *
     * @name PaymentsCryptoControllerCheckStatus
     * @request POST:/api/payments-crypto/status
     * @response `201` `void`
     */
    paymentsCryptoControllerCheckStatus: (data: PaymentStatusRequestDto, params: RequestParams = {}) =>
      this.http.request<{ status: string }, any>({
        path: `/api/payments-crypto/status`,
        method: "POST",
        body: data,
        format: 'json',
        type: ContentType.Json,
      }),

    /**
     * No description
     *
     * @tags subscriptions-crypto
     * @name SubscriptionsCryptoControllerCreate
     * @summary Создать подписку криптовую
     * @request POST:/api/subscriptions-crypto
     * @secure
     * @response `200` `BooleanResponseDto`
     * @response `401` `void` Не авторизован
     */
    subscriptionsCryptoControllerCreate: (data: CreateSubscriptionsCryptoDto, params: RequestParams = {}) =>
      this.http.request<BooleanResponseDto, void>({
        path: `/api/subscriptions-crypto`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags subscriptions-crypto
     * @name SubscriptionsCryptoControllerCreateTransaction
     * @summary Создать транзакцию хук
     * @request POST:/api/subscriptions-crypto/smarty_pay_hook
     * @response `200` `BooleanResponseDto`
     */
    subscriptionsCryptoControllerCreateTransaction: (params: RequestParams = {}) =>
      this.http.request<BooleanResponseDto, any>({
        path: `/api/subscriptions-crypto/smarty_pay_hook`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payments-debt
     * @name PaymentsDebtControllerCreate
     * @request POST:/api/payments-debt/hooks
     * @response `201` `void`
     */
    paymentsDebtControllerCreate: (params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/payments-debt/hooks`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminControllerCheckSubscribe
     * @summary Синхронизировать подписки c cloudPayments
     * @request GET:/api/admin/cloudpayments_subscribe_check
     * @secure
     * @response `200` `BooleanResponseDto`
     * @response `401` `void` Не авторизован
     */
    adminControllerCheckSubscribe: (params: RequestParams = {}) =>
      this.http.request<BooleanResponseDto, void>({
        path: `/api/admin/cloudpayments_subscribe_check`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminControllerCreateSubscribe
     * @summary Создать подписку по id транзакции
     * @request POST:/api/admin/create_subscribe_in_cp
     * @secure
     * @response `200` `BooleanResponseDto`
     * @response `401` `void` Не авторизован
     */
    adminControllerCreateSubscribe: (data: CreateSubscribeAdminDto, params: RequestParams = {}) =>
      this.http.request<BooleanResponseDto, void>({
        path: `/api/admin/create_subscribe_in_cp`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminControllerSetAllStartSubscriptionsDays
     * @summary Синхронизировать подписки c cloudPayments
     * @request GET:/api/admin/setAllStartSubscriptionsDays
     * @secure
     * @response `200` `BooleanResponseDto`
     * @response `401` `void` Не авторизован
     */
    adminControllerSetAllStartSubscriptionsDays: (params: RequestParams = {}) =>
      this.http.request<BooleanResponseDto, void>({
        path: `/api/admin/setAllStartSubscriptionsDays`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminControllerGenerateTokenByUserId
     * @summary Сгенерировать
     * @request GET:/api/admin/users_token/{id}
     * @secure
     * @response `200` `BooleanResponseDto`
     * @response `401` `void` Не авторизован
     */
    adminControllerGenerateTokenByUserId: (id: number, params: RequestParams = {}) =>
      this.http.request<BooleanResponseDto, void>({
        path: `/api/admin/users_token/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminControllerDeleteDiscordUsers
     * @summary Удалить всех кто лишний в дискорде
     * @request GET:/api/admin/delete_discrod_users
     * @secure
     * @response `200` `BooleanResponseDto`
     * @response `401` `void` Не авторизован
     */
    adminControllerDeleteDiscordUsers: (params: RequestParams = {}) =>
      this.http.request<BooleanResponseDto, void>({
        path: `/api/admin/delete_discrod_users`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminControllerSendActivation
     * @summary Разослать всем неактивным подтверждение почты
     * @request GET:/api/admin/sendActivationForAllInactiveUsers
     * @secure
     * @response `200` `BooleanResponseDto`
     * @response `401` `void` Не авторизован
     */
    adminControllerSendActivation: (params: RequestParams = {}) =>
      this.http.request<BooleanResponseDto, void>({
        path: `/api/admin/sendActivationForAllInactiveUsers`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags promo-codes
     * @name PromoCodesControllerVerifyPromoCode
     * @summary Получить информацию о промокоде
     * @request POST:/api/promo-codes
     * @response `200` `PromoCode`
     */
    promoCodesControllerVerifyPromoCode: (data: VerifyPromoCodeDto, params: RequestParams = {}) =>
      this.http.request<PromoCode, any>({
        path: `/api/promo-codes`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
 * No description
 *
 * @tags system
 * @name SystemControllerCheck
 * @request GET:/api/system/healthCheck
 * @response `200` `{
  \** @example "ok" *\
    status?: string,
  \** @example {"database":{"status":"up"}} *\
    info?: Record<string,{
    status?: string,
    [key: string]: any,

}>,
  \** @example {} *\
    error?: Record<string,{
    status?: string,
    [key: string]: any,

}>,
  \** @example {"database":{"status":"up"}} *\
    details?: Record<string,{
    status?: string,
    [key: string]: any,

}>,

}` The Health Check is successful
 * @response `503` `{
  \** @example "error" *\
    status?: string,
  \** @example {"database":{"status":"up"}} *\
    info?: Record<string,{
    status?: string,
    [key: string]: any,

}>,
  \** @example {"redis":{"status":"down","message":"Could not connect"}} *\
    error?: Record<string,{
    status?: string,
    [key: string]: any,

}>,
  \** @example {"database":{"status":"up"},"redis":{"status":"down","message":"Could not connect"}} *\
    details?: Record<string,{
    status?: string,
    [key: string]: any,

}>,

}` The Health Check is not successful
 */
    systemControllerCheck: (params: RequestParams = {}) =>
      this.http.request<
        {
          /** @example "ok" */
          status?: string;
          /** @example {"database":{"status":"up"}} */
          info?: Record<
            string,
            {
              status?: string;
              [key: string]: any;
            }
          >;
          /** @example {} */
          error?: Record<
            string,
            {
              status?: string;
              [key: string]: any;
            }
          >;
          /** @example {"database":{"status":"up"}} */
          details?: Record<
            string,
            {
              status?: string;
              [key: string]: any;
            }
          >;
        },
        {
          /** @example "error" */
          status?: string;
          /** @example {"database":{"status":"up"}} */
          info?: Record<
            string,
            {
              status?: string;
              [key: string]: any;
            }
          >;
          /** @example {"redis":{"status":"down","message":"Could not connect"}} */
          error?: Record<
            string,
            {
              status?: string;
              [key: string]: any;
            }
          >;
          /** @example {"database":{"status":"up"},"redis":{"status":"down","message":"Could not connect"}} */
          details?: Record<
            string,
            {
              status?: string;
              [key: string]: any;
            }
          >;
        }
      >({
        path: `/api/system/healthCheck`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags news
     * @name NewsControllerGetNews
     * @summary Новости криптоисточников
     * @request POST:/api/news/getNews
     * @response `200` `NewsResponseDto`
     */
    newsControllerGetNews: (data: GetNewsDto, params: RequestParams = {}) =>
      this.http.request<NewsResponseDto, any>({
        path: `/api/news/getNews`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags crm
     * @name CrmControllerSendPersonalDataInCrm
     * @summary Сохранить данные в crm
     * @request POST:/api/crm/contact
     * @response `200` `any`
     */
    crmControllerSendPersonalDataInCrm: (data: CreateCrmContactDto, params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/api/crm/contact`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags crm
     * @name CrmControllerGetPersonalDataInCrm
     * @summary Сохранить данные в crm
     * @request GET:/api/crm/contact/{email}
     * @response `200` `any`
     */
    crmControllerGetPersonalDataInCrm: (email: string, params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/api/crm/contact/${email}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}