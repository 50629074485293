import React, {
  useCallback, useEffect
} from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Button, Menu, MenuItem, Typography,
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { useTranslation } from 'react-i18next';
import MobXRouterDecorator from '$components/HOC/MobXRouterDecorator';
import SvgIcons from '$common/SvgIcons';
import { MOBXDefaultProps, RolesTypes, RouterEnum } from '$types';
import Colors from '$colors';
import { useRootStore } from '$hooks/useRootStore';
import { useRootService } from '$hooks/useRootService';

function LinkLine(props: MOBXDefaultProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const stores = useRootStore();
  const { t } = useTranslation();
  const services = useRootService();
  const { user } = stores.UserStore;
  const isUserAuth = !!user?.isActivated;
  const open = Boolean(anchorEl);
  const [headerArrayToRender, setHeaderArrayToRender] = React.useState<Array<any>>([]);
  const [menuArrayToRender, setMenuArrayToRender] = React.useState<Array<any>>([]);

  const headerArray = [
    {
      text: t('header.lessons'),
      icon: <SvgIcons name="lessons" color={Colors.gray2} />,
      cb: () => {
        props.history.push(RouterEnum.CATEGORY);
      },
    },
    {
      text: t('header.tools'),
      icon: <SvgIcons name="settings" width={22} height={22} color={Colors.gray2} />,
      cb: () => {
        props.history.push(RouterEnum.TOOLS);
      },
    },
    {
      text: t('header.news'),
      icon: <NewspaperIcon style={{ color: Colors.gray2 }} />,
      cb: () => {
        props.history.push(RouterEnum.NEWS);
      },
    },
    {
      text: t('header.e_books'),
      icon: <MenuBookIcon style={{ color: Colors.gray2 }} />,
      cb: () => {
        props.history.push(RouterEnum.OTHER);
      },
    },
    {
      condition: user?.can_to_subscribe,
      isProButton: 'pro_link',
      text: t('header.AcademyPRO'),
      textColor: Colors.blue4,
      icon: <CurrencyBitcoinIcon style={{ color: Colors.gray2 }} />,
      noIconInHeader: true,
      cb: () => {
        props.history.push(RouterEnum.ACADEMYPRO);
      },
    },
    {
      condition: !isUserAuth,
      text: t('header.signIn'),
      icon: <SvgIcons name="user" />,
      cb: (e) => {
        props.history.push(RouterEnum.SIGNIN);
      },
    },
    {
      className: isMobile && 'emailName',
      condition: isUserAuth,
      text: user.email,
      endIcon: <ArrowDropDownIcon style={{ color: Colors.gray2 }} />,
      cb: (e) => {
        setAnchorEl(e.currentTarget);
      },
    },

  ];

  const menuArray = [
    {
      condition: isUserAuth,
      text: t('header.profile'),
      icon: <AccountCircleIcon style={{ color: Colors.gray2 }} />,
      cb: (e) => {
        props.history.push(RouterEnum.PROFILE);
      },
    },
    {
      condition: isUserAuth,
      text: t('header.partner_profile'),

      icon: <AttachMoneyIcon style={{ color: Colors.blue1 }} />,
      cb: (e) => {
        props.history.push(RouterEnum.REFERRER);
      },
    },
    {
      condition: isUserAuth,
      text: t('header.logout'),
      icon: <ExitToAppIcon style={{ color: Colors.blue1 }} />,
      cb: (e) => {
        services.AuthService.logout();
      },
    },
  ];

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getComponents = (item, header = true) => {
    if ('condition' in item && !item.condition) return null;
    return (
      <Button
        className={(item.className || '') + (!header ? ' pointer-event-none' : '')}
        key={item.text}
        variant={item.isProButton || 'link'}
        onClick={(e) => {
          item.cb(e);
        }}
        style={{
          maxWidth: 300,
          boxShadow: 'none',
        }}
        startIcon={header && item.noIconInHeader ? null : item.icon}
        endIcon={item.endIcon || null}
      >
        <Typography
          variant="link"
          color={!header ? Colors.black : item.textColor || Colors.white}
        >
          {item.text}
        </Typography>
      </Button>
    );
  };

  const [swapMenuAnchorEl, setSwapMenuAnchorEl] = React.useState(null);

  const handleSwapMenuClick = (event) => {
    setSwapMenuAnchorEl(event.currentTarget);
  };

  const handleSwapMenuClose = () => {
    setSwapMenuAnchorEl(null);
  };

  const handleCryptoSwapClick = () => {
    props.history.push(RouterEnum.SWAP);
    handleSwapMenuClose();
  };

  const handleExchangeClick = () => {
    props.history.push(RouterEnum.EXCHANGE);
    handleSwapMenuClose();
  };

  const swapMenu = [
    {
      text: 'Swap',
      icon: <SvgIcons name="btc" color={Colors.gray2} />,
      cb: handleCryptoSwapClick,
    },
    {
      text: 'Обменник',
      icon: <SvgIcons name="usd" color={Colors.gray2} />,
      cb: handleExchangeClick,
    },
  ];

  if (isMobile && !isUserAuth && window.innerWidth < 1123) {
    headerArray.push(
      {
        icon: <MenuIcon style={{ color: Colors.blue1 }} />,
        cb: (e) => {
          setAnchorEl(e.currentTarget);
          // setSwapMenuAnchorEl(e.currentTarget);
        },
      },
    );
  }

  const updateElements = useCallback((length) => {
    const totalLength = headerArray.length + menuArray.length;
    if (totalLength > 0 && totalLength <= length) {
      setHeaderArrayToRender(headerArray);
      setMenuArrayToRender(menuArray);
    } else if (totalLength > length) {
      if (length <= 0) {
        length = 1;
      }
      setHeaderArrayToRender(headerArray.map(e => e).reverse().slice(0, length).reverse());
      setMenuArrayToRender([...swapMenu, ...headerArray.map(e => e).reverse().slice(length).reverse(), ...menuArray]);
    }
  }, []);

  const resizeListener = useCallback(() => {
    const maxWidth = Math.floor(window.innerWidth / 1.1);
    updateElements(Math.floor(maxWidth / 150));
  }, []);

  useEffect(() => {
    resizeListener();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [headerArray, menuArray]);

  return (
    <div className="d-flex justify-content-center flex-row gap-2 align-items-center w-fit">

      {!isMobile && getComponents({
        text: 'Exchange',
        icon: <SvgIcons name="swap" color={Colors.gray2} />,
        cb: handleSwapMenuClick,
      })}

      <Menu
        anchorEl={swapMenuAnchorEl}
        open={Boolean(swapMenuAnchorEl)}
        onClose={handleSwapMenuClose}
      >
        {swapMenu.map(item => {
          return (
            <MenuItem
              key={item.text}
              onClick={(e) => {
                item.cb && item.cb(e);
              }}
            >
              {getComponents(item, false)}
            </MenuItem>
          );
        })}
      </Menu>
      {headerArrayToRender.map(el => getComponents(el))}

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        style={{
          width: anchorEl?.offsetWidth,
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
      >
        {menuArrayToRender.filter(item => ('condition' in item ? item.condition : true)).map(item => {
          return (
            <MenuItem
              key={item.text}
              onClick={(e) => {
                item.cb && item.cb(e);
              }}
            >
              {getComponents(item, false)}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}

export default MobXRouterDecorator(LinkLine);
